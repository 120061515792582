import React, { useState, useEffect } from "react";

import ScriptAvatarPicker from "@/components/scripts/ScriptAvatarPicker";
import AvatarImages from "@/components/scripts/AvatarImages";
import AvatarImage from "@/components/scripts/AvatarImage";
import PermissionsGate from '@/components/permissions/PermissionsGate';
import AdvancedConfigurationToggler from '@/components/scripts/Shared/AdvancedConfigurationToggler';
import { New } from "@/components/scripts/Shared/New";

import ReactFlagsSelect from 'react-flags-select';

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';
import { can } from "@/lib/user-permission";

import { Container } from "./styles";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const DesignContent = ({ isMobile }) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);
  const desktopContent = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.desktopContent);
  const mobileContent = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.mobileContent);
  const disbleChat = useContextSelector(ScriptContext, ({ state }) => state.disablePreviewChat)

  let convoScript = desktopContent;
  let dispatchActionType = "SET_DESKTOP_CONVOSCRIPT_VALUE";

  if (isMobile) {
    convoScript = mobileContent;
    dispatchActionType = "SET_MOBILE_CONVOSCRIPT_VALUE";
  }

  useEffect(() => {
    window.initJqueryPopover();
  }, []);

  const delayedHandleChangeColor = _.debounce((value) => {
    handleChangeColor(value);
  }, 200)

  const handleChangeColor = (value) => {
    if (!hasPermission) return;

    setScriptValue("userMainColor", value);
    setScriptValue("robotMainColor", value);
  };

  const handleChangeOpenningTime = (value) => {
    if (!hasPermission) return;

    setScriptValue("waitTime", value)
  };

  const handleChangeAvatar = (avatar) => {
    if (!hasPermission) return;

    setScriptValue("avatar", avatar)
  };

  const setScriptValue = (key, value) => {
    dispatch({ type: dispatchActionType, payload: { key, value } });
    dispatch({
      type: "SET_PREVIEW_CONVOSCRIPT_VALUE",
      payload: { key, value },
    });
  };

  const setFullScreenEnabled = (isEnabled) => {
    if (!hasPermission) return;

    const fullScreenFields = {
      modalMode: isEnabled,
      alwaysAutoOpen: isEnabled,
      disable1stPartyBranding: isEnabled,
    };

    for (const [field, value] of Object.entries(fullScreenFields)) {
      setScriptValue(field, value)
    }
  };

  const setDisableChatbotAfterConversion = (disable) => {
    if (!hasPermission) return;

    setScriptValue('disableChatbotAfterConversion', disable);
  };

  const changeLanguage = (code) => {
    if (!hasPermission) return;

    setSelected(code);
    setScriptValue("lang", mapCountryCodeToLanguage(code));
  }

  const handleChangeRestartAtEnd = (value) => {
    if (!hasPermission) return;

    setScriptValue("chatRestartAtEnd", value)
  };

  const mapCountryCodeToLanguage = (code) => {
    switch (code) {
      case 'US':
        return 'en-US';
      case 'ES':
        return 'es-ES';
      case 'NL':
        return 'nl-NL';
      case 'DE':
        return 'de-DE';
      case 'IT':
        return 'it-IT';
      case 'SA':
        return 'ar-SA';
      case 'IN':
        return 'hi-IN';
      case 'RU':
        return 'ru-RU';
      case 'FR':
        return 'fr-FR';
      default:
        return 'pt-BR';
    }
  }

  const handleChangeDisableChat = (value) => {
    if (!window.neurolead) return;

    if (value) {
      neurolead.close()
      neurolead.hideAvatar()
    } else {
      neurolead.showAvatar()
      neurolead.open()
    }

    dispatch({
      type: "SET_DISABLE_CHAT",
      payload: value,
    });
  }

  const mapLanguageCodeToCountry = (code) => {
    switch (code) {
      case 'en-US':
        return 'US';
      case 'es-ES':
        return 'ES';
      case 'nl-NL':
        return 'NL';
      case 'de-DE':
        return 'DE';
      case 'it-IT':
        return 'IT';
      case 'ar-SA':
        return 'SA';
      case 'hi-IN':
        return 'IN';
      case 'ru-RU':
        return 'RU';
      case 'fr-FR':
        return 'FR';
      default:
        return 'BR';
    }
  }

  const baseColors = [
    "#FBC02D",
    "#F44336",
    "#E91E63",
    "#2196F3",
    "#4CAF50",
    "#9E9E9E",
  ];

  const [selected, setSelected] = useState(mapLanguageCodeToCountry(convoScript.lang));

  return (
    <Container>
      <div className="d-flex">
        <div>
          <label className="form-label">{I18n.t("views.manage_flows.script_editor.color")}</label>
          <PermissionsGate scope={PERMISSION_SCOPE} usePopover>
            <div className="row gx-2 mb-3">
              {baseColors.map((color) => {
                return (
                  <div key={color} className="col-auto">
                    <label className="form-colorinput">
                      <input
                        type="radio"
                        value={color}
                        className="form-colorinput-input"
                        disabled={!hasPermission}
                        checked={convoScript.userMainColor.toUpperCase() === color}
                        onChange={(event) => handleChangeColor(event.target.value)}
                      />

                      <span className="form-colorinput-color" style={{ backgroundColor: color }}></span>
                    </label>
                  </div>
                );
              })}

              <div className="col-auto">
                <label className="form-colorinput custom-color">
                  <input
                    type="color"
                    value={convoScript.userMainColor}
                    className="form-colorinput-input"
                    disabled={!hasPermission}
                    onChange={(event) => delayedHandleChangeColor(event.target.value)}
                  />

                  <span
                    className={`form-colorinput-color ${!baseColors.includes(convoScript.userMainColor.toUpperCase()) && 'checked'}`}
                    style={{ backgroundColor: convoScript.userMainColor }}
                  >
                    {I18n.t("views.manage_flows.script_editor.other")}
                  </span>
                </label>
              </div>
            </div>
          </PermissionsGate>
        </div>
      </div>

      <div className="mb-3">
        <label className="form-label">{I18n.t("views.manage_flows.script_editor.title")}</label>

        <input
          type="text"
          className="form-control"
          placeholder={I18n.t("views.manage_flows.script_editor.title_placeholder")}
          defaultValue={convoScript.title}
          onChange={(event) => setScriptValue("title", event.target.value)}
        />
      </div>

      <div style={(isMobile ? { visibility: "hidden", display: "none" } : { visibility: "visible" })} className="mb-3">
        <div className="d-flex justify-content-start">
          <label className="form-label">
            {I18n.t("views.manage_flows.script_editor.screen_chat_opening_time")} {" "}
            <span id="time-display">
              {Math.floor((convoScript.waitTime || 0) / 500) / 2}s
            </span>

            <span className="form-help ms-2 d-none d-md-inline-flex" data-bs-toggle="popover" data-bs-html="true" data-bs-trigger="hover"
              data-bs-content={I18n.t("views.manage_flows.script_editor.automatic_chat_opening_html")}
            >?</span>
          </label>
        </div>

        <PermissionsGate scope={PERMISSION_SCOPE} usePopover>
          <div>
            <input
              type="range"
              className="form-range"
              step="500"
              min="0"
              max="60000"
              disabled={!hasPermission}
              defaultValue={convoScript.waitTime || 0}
              onChange={(event) => handleChangeOpenningTime(event.target.value)}
            />
          </div>
        </PermissionsGate>
      </div>

      <div className="mb-3">
        <div className="d-flex justify-content-start">
          <label className="form-label">
            {I18n.t("views.manage_flows.script_editor.chat_spacing_with_border_margin")} {" "}
            <span id="time-display">
              {convoScript.marginBottom || 0}px
            </span>

            <span className="form-help ms-2 d-none d-md-inline-flex" data-bs-toggle="popover" data-bs-html="true" data-bs-trigger="hover"
              data-bs-content={I18n.t("views.manage_flows.script_editor.elevation_of_assistant_placement_on_site_html")}
            >?</span>
          </label>
        </div>

        <input
          type="range"
          className="form-range"
          step="5"
          min="0"
          max="60"
          defaultValue={convoScript.marginBottom || 0}
          onChange={(event) => setScriptValue("marginBottom", event.target.value)}
        />
      </div>

      <div className="mb-3">
        <div className="form-label">
          {I18n.t("views.manage_flows.script_editor.display_position_on_the_site")}
        </div>

        <div className='d-flex'>
          <label className="form-check form-switch">
            <input className="form-check-input" type="radio"
              value="left"
              checked={convoScript.side == "left"}
              onChange={(event) => setScriptValue("side", event.target.value)}
            />
            <span className="form-check-label">{I18n.t("views.manage_flows.script_editor.left")}</span>
          </label>

          <label className="form-check form-switch ms-2">
            <input className="form-check-input" type="radio"
              value="right"
              checked={convoScript.side == "right"}
              onChange={(event) => setScriptValue("side", event.target.value)}
            />
            <span className="form-check-label">{I18n.t("views.manage_flows.script_editor.right")}</span>
          </label>
        </div>
      </div>

      <div className="mb-2">
        <label className="form-label">{I18n.t("views.manage_flows.script_editor.bot_avatar")}</label>

        <div className='d-flex'>
          <PermissionsGate scope={PERMISSION_SCOPE} usePopover>
            <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
              {hasPermission ? (
                <ScriptAvatarPicker
                  avatar={convoScript.avatar}
                  onSuccess={(avatar) => handleChangeAvatar(avatar)}
                />
              ) : (
                <AvatarImage className="cursor-pointer me-2 mb-2" />
              )}

              <AvatarImages
                selectedAvatar={convoScript.avatar}
                onChange={(avatar) => handleChangeAvatar(avatar)}
              />
            </div>
          </PermissionsGate>
        </div>
        <div className="d-block">
          <PermissionsGate scope={PERMISSION_SCOPE} usePopover useLockIcon>
            <fieldset className="mt-3">
              <div className="mb-2">
                <label className='form-label'>
                  {I18n.t("views.manage_flows.script_editor.select_wizard_language")}
                </label>

                <div className="col-lg-4 col-md-6 row">
                  <ReactFlagsSelect
                    countries={["US", "BR", "ES", "NL", "DE", "IT", "SA", "IN", "RU", "FR"]}
                    customLabels={{ "US": "EN-US", "BR": "PT-BR", "ES": "ES-ES", "NL": "NL-NL", "DE": "DE-DE", "IT": "IT-IT", "SA": "AR-SA", "IN": "HI-IN", "RU": "RU-RU", "FR": "FR-FR" }}
                    selected={selected}
                    onSelect={code => changeLanguage(code)}
                    placeholder={I18n.t("views.manage_flows.script_editor.select_the_language")}
                    showSelectedLabel={true}
                    showOptionLabel={true}
                    disabled={!hasPermission}
                  />
                </div>
              </div>
              <p className="mb-0">
                <i className="ti ti-alert-triangle text-muted icon me-2"></i>
                {I18n.t("views.manage_flows.script_editor.the_chat_will_change_all_texts")}
              </p>
            </fieldset>
          </PermissionsGate>
        </div>
      </div>

      <hr className="mb-3 mt-2" />
      <AdvancedConfigurationToggler>
        <>
          <fieldset className="form-fieldset mt-3">
            <div className="cancel-preview-chat">
              <label className="form-check form-switch mb-2">
                <input className="form-check-input"
                       type="checkbox"
                       checked={disbleChat}
                       onChange={(event) => handleChangeDisableChat(event.target.checked)}
                />
                <span className="form-check-label fw-medium">
                  {I18n.t("views.manage_flows.script_editor.cancel_live_chat_preview")}
                </span>
              </label>
              <New/>
            </div>

            <p className="mb-0">
              <i className="ti ti-alert-triangle text-muted icon me-2"></i>
              {I18n.t("views.manage_flows.script_editor.disable_chat")}
            </p>
          </fieldset>

          <PermissionsGate scope={PERMISSION_SCOPE} usePopover useLockIcon>
            <fieldset className="form-fieldset mt-3">
              <label className="form-check form-switch mb-2">
                <input className="form-check-input"
                  type="checkbox"
                  checked={convoScript.modalMode === true}
                  onChange={(event) => setFullScreenEnabled(event.target.checked)}
                />
                <span className="form-check-label fw-medium">
                  {I18n.t("views.manage_flows.script_editor.fullscreen")}
                </span>
              </label>

              <p className="mb-0">
                <i className="ti ti-alert-triangle text-muted icon me-2"></i>
                {I18n.t("views.manage_flows.script_editor.chat_will_overlay_all_content_on_your_site")}
              </p>
            </fieldset>
          </PermissionsGate>

          <PermissionsGate scope={PERMISSION_SCOPE} usePopover useLockIcon>
            <fieldset className="form-fieldset mt-3">
              <label className="form-check form-switch mb-2">
                <input className="form-check-input"
                  type="checkbox"
                  checked={!!convoScript.disableChatbotAfterConversion}
                  onChange={(event) => setDisableChatbotAfterConversion(event.target.checked)}
                />
                <span className="form-check-label fw-medium">
                  {I18n.t("views.manage_flows.script_editor.hide_chatbot_after_conversion")}
                </span>
              </label>

              <p className="mb-0">
                <i className="ti ti-alert-triangle text-muted icon me-2"></i>
                {I18n.t("views.manage_flows.script_editor.the_chat_will_disappear_for_the_already_converted_user")}
              </p>
            </fieldset>
          </PermissionsGate>

          <PermissionsGate scope={PERMISSION_SCOPE} usePopover useLockIcon>
            <fieldset className="form-fieldset mt-3">
              <label className="form-check form-switch mb-2">
                <input className="form-check-input"
                  type="checkbox"
                  checked={!!convoScript.chatRestartAtEnd}
                  onChange={(event) => handleChangeRestartAtEnd(event.target.checked)}
                />
                <span className="form-check-label fw-medium">
                  {I18n.t("views.manage_flows.script_editor.button_to_restart_chatbot")}
                </span>
              </label>

              <p className="mb-0">
                <i className="ti ti-alert-triangle text-muted icon me-2"/>
                {I18n.t("views.manage_flows.script_editor.allowing_to_restart_the_chatbot")}
              </p>
            </fieldset>
          </PermissionsGate>
        </>
      </AdvancedConfigurationToggler>
    </Container>
  );
};

export default DesignContent;
