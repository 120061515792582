import parse from "html-react-parser";

const EmptyListAlert = () => (
  <div className="alert alert-warning mb-0">
    <div className="d-flex align-items-center">
      <i className="ti ti-alert-circle icon alert-icon" />
      <div className="alert-message text-body">
        {parse(I18n.t("views.leadster_ai.page.add_content.content_list.empty_list_html"))}
      </div>
    </div>
  </div>
);

export default EmptyListAlert;
