import { useCallback, useState } from "react";

import { Stack } from "react-bootstrap";

import Pagination from "@/components/shared/Pagination";
import Content from "./components/Content";
import SkeletonLoader from "./components/SkeletonLoader";
import EmptyListAlert from "./components/EmptyListAlert";

import { useAssistantContext } from "@/contexts/AssistantContext";
import usePagination from "@/hooks/usePagination";

import { classBinding } from "@/utils/helpers";

const ContentList = () => {
  const { assistant, debitCharactersUsed } = useAssistantContext();

  const [knowledgeBases, setKnowledgeBases] = useState([]);
  const [baseType, setBaseType] = useState("");

  const { paginationData, isLoading, setCurrentPage, setPerPage } = usePagination(
    `/ai/assistants/${assistant.id}/knowledge-bases`,
    {
      dataIndex: "ai/knowledge_bases",
      filters: { base_type: baseType },
      dependencies: [baseType],
      onSuccess: (data) => setKnowledgeBases(data),
    }
  );

  const handleRemoveKnowledgeBase = (knowledgeBase) => {
    const updatedKnowledgeBases = knowledgeBases.filter((base) => base.id !== knowledgeBase.id);

    setKnowledgeBases([...updatedKnowledgeBases]);
    debitCharactersUsed(knowledgeBase.character_count);
  };

  return (
    <div>
      <label className="form-label">
        <i className="ti ti-files icon text-primary me-2" />
        {I18n.t("views.leadster_ai.page.add_content.content_list.title", {
          total: paginationData.total_count,
        })}
      </label>

      <div className="row mb-3">
        <div className="col-auto ms-auto">
          <select
            className="form-control form-select"
            onChange={(e) => setBaseType(e.target.value)}
            value={baseType}
          >
            <option value="">
              {I18n.t("views.leadster_ai.page.add_content.content_list.base_type_options.empty")}
            </option>
            <option value="file">
              {I18n.t("views.leadster_ai.page.add_content.content_list.base_type_options.file")}
            </option>
            <option value="url">
              {I18n.t("views.leadster_ai.page.add_content.content_list.base_type_options.url")}
            </option>
          </select>
        </div>
      </div>

      <div className={classBinding({ "content-loading": isLoading })}>
        {isLoading && !knowledgeBases.length ? <SkeletonLoader /> : null}
        {!isLoading && !knowledgeBases.length ? <EmptyListAlert /> : null}

        <Stack direction="vertical" gap={2}>
          {knowledgeBases.map((knowleadgeBase) => {
            return (
              <Content
                key={knowleadgeBase.id}
                knowleadgeBase={knowleadgeBase}
                onRemoveKnowledgeBase={() => handleRemoveKnowledgeBase(knowleadgeBase)}
              />
            );
          })}
        </Stack>

        <Pagination
          className="mb-0"
          isPageRangeEnabled
          onCurrentPageChange={setCurrentPage}
          onPerPageChange={setPerPage}
          pageCount={paginationData.total_pages}
          perPage={paginationData.limit_value}
        />
      </div>
    </div>
  );
};

export default ContentList;
