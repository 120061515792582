import { ACTIONS } from "./constants";

export const initialState = {
  settings: {
    title: "Title",
    description: "Description",
    pages: {
      assistant: {
        subpages: {
          createYourAiAssistant: {
            title: "Title",
            description: null,
            currentStep: "",
          },
        },
      },
    },
  },

  assistants: [],
  assistant: null,

  totalCharacters: 0,
};

export function assistantReducer(state, action) {
  switch (action.type) {
    case ACTIONS.UPDATE_SETTINGS:
      return { ...state, settings: { ...state.settings, ...action.payload } };
    case ACTIONS.RESET_ASSISTANT_DATA:
      return { ...state, assistant: initialState.assistant };
    case ACTIONS.SET_ASSISTANTS_LIST:
      return { ...state, assistants: action.payload };
    case ACTIONS.SET_ASSISTANT:
      return { ...state, assistant: action.payload };
    case ACTIONS.DEBIT_CHARACTERS_USED:
      return {
        ...state,
        assistant: {
          ...state.assistant,
          character_used: state.assistant.character_used - action.payload,
        },
      };
    case ACTIONS.REMOVE_ASSISTANT:
      return {
        ...state,
        assistants: state.assistants.filter((assistant) => assistant.id !== action.payload),
      };
    case ACTIONS.ADD_PENDING_BASE:
      return {
        ...state,
        assistant: {
          ...state.assistant,
          pending_knowledge_base: action.payload,
        },
      };
    case ACTIONS.REMOVE_PENDING_BASE:
      return {
        ...state,
        assistant: {
          ...state.assistant,
          pending_knowledge_base: null,
        },
      };
    default:
      return state;
  }
}
