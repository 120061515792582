import { useRef, createElement } from "react";

import ScriptBlockScheduler from "./ScriptBlockScheduler";
import ScriptBlockWhatsapp from "./ScriptBlockWhatsapp";
import ScriptBlockAutoRedirect from "./ScriptBlockAutoRedirect";
import ScriptBlockJivochat from "./ScriptBlockJivochat";
import ScriptBlockSay from "./ScriptBlockSay";
import ScriptBlockMessagesSelector from "./ScriptBlockMessagesSelector";
import ScriptBlockSelector from "./ScriptBlockSelector";
import ScriptBlockSave from "./ScriptBlockSave";
import ScriptBlockConditional from "./ScriptBlockConditional";
import ScriptBlockCustom from "./ScriptBlockCustom";
import ScriptBlockIaInteraction from "./ScriptBlockIaInteraction";
import AddBlockButton from "./ScriptEditorParts/AddBlockButton";
import DragAndDropButton from "./ScriptEditorParts/DragAndDropButton";
import Tree from "./Tree";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from "use-context-selector";

import { can } from "@/lib/user-permission";
import { setBlockUniqueLabel } from "./ScriptEditor/scriptFieldLabels";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const Item = (props) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);

  const itemRef = useRef();

  const {
    id, isMobile, path, placeholder, dragging, data, type, children,
    onDragStart, onDragEnd, onDragOver, onDrop,
    treeAdd, treeRemove, treeUpdate, makeBlock, parent, isLast, isOnly,
    getFullTree, globalKeyGet, globalKeySet, additionalData
  } = props;

  const itemWithoutHandlers = useRef(
    Object.keys(props).reduce(
      (obj, key) =>
        !(key.startsWith("on") && key[2].toUpperCase() == key[2])
          ? Object.assign(obj, { [key]: props[key] })
          : obj,
      {}
    )
  );

  const onDelete = () => {
    if (!hasPermission) return;

    let payload = "from";

    if (!!id) {
      payload = id.replace(/^\D+/g, "");
    }

    treeRemove(itemWithoutHandlers.current);
    dispatch({ type: "REMOVE_DYNAMIC_FIELD", payload });
  };

  const onDuplicate = () => {
    const item = itemWithoutHandlers.current;
    const path = item.path.concat(item.id);
    const newBlock = { ...item.data, uid: "" };

    setBlockUniqueLabel(newBlock);

    item.treeAdd(item, item.makeBlock(path, newBlock), "below");
  };

  const mapAttributesName = (children, arr, type = null) => {
    children.map(function (e) {
      if (Object.keys(e).includes("save")) {
        if (e["save"].startsWith("nosync::")) return;
        if (!e["save"].trim()) return;
        if (!!type && e["type"] !== type) return;

        arr.push(e["save"]);
      } else if (Object.keys(e).includes("if")) {
        mapAttributesName([e["then"]], arr, type);
      }
    });
  };

  const getSelectorOptions = (blockSaveName) => {
    const blocks = getFullTree() || [];

    const matchingBlock = blocks.find((block) => {
      const isSelectorBlock = "save" in block && "from" in block;
      const isMatchingBlock = isSelectorBlock && block.save === blockSaveName;

      return isMatchingBlock;
    });

    return matchingBlock ? matchingBlock.from : [];
  };

  const setDefinition = (data) => {
    treeUpdate(itemWithoutHandlers.current, { data: { ...data } });
  };

  const getDefinitionType = (def) => {
    if ("say" in def) {
      if ("scheduler" in def && def.scheduler) {
        return ScriptBlockScheduler;
      } else if ("whatsapp" in def && def.whatsapp) {
        return ScriptBlockWhatsapp;
      } else if ("jivochat" in def && def.jivochat) {
        return ScriptBlockJivochat;
      } else if ("autoRedirect" in def && def.autoRedirect) {
        return ScriptBlockAutoRedirect;
      } else if ("iaInteraction" in def && def.iaInteraction) {
        return ScriptBlockIaInteraction;
      } else {
        return ScriptBlockSay;
      }
    } else if ("from" in def) {
      if ("messages" in def) {
        return ScriptBlockMessagesSelector;
      } else {
        return ScriptBlockSelector;
      }
    } else if ("save" in def) {
      return ScriptBlockSave;
    } else if ("if" in def) {
      return ScriptBlockConditional;
    } else if ("api" in def) {
      return ScriptBlockCustom;
    } else {
      return null;
    }
  };

  if (placeholder) {
    return (
      <div
        ref={itemRef}
        className="targeted placeholder-drop"
        onDragOver={(e) => onDragOver(e, itemWithoutHandlers.current, itemRef.current)}
        onDrop={(e) => onDrop(e, itemWithoutHandlers.current)}
      />
    );
  }

  const currentBlock = getDefinitionType(data);
  const isDragAndDropAllowed = ![ScriptBlockMessagesSelector].includes(currentBlock);

  return (
    <div
      ref={itemRef}
      onDragOver={(e) => onDragOver(e, itemWithoutHandlers.current, itemRef.current)}
      onDrop={(e) => onDrop(e, itemWithoutHandlers.current)}
    >
      <div className={`item ${dragging ? "dragged" : ""}`}>
        <div className="item-content d-flex align-items-start">
          {isDragAndDropAllowed &&  (
            <DragAndDropButton
              onDragStart={(e) => onDragStart(e, itemWithoutHandlers.current, itemRef.current)}
              onDragEnd={(e) => onDragEnd(e, itemWithoutHandlers.current, itemRef.current)}
            />
          )}

          <div className="col">
            {createElement(currentBlock, {
              isMobile: isMobile,
              definition: data,
              treeAdd: treeAdd,
              treeRemove: treeRemove,
              treeUpdate: treeUpdate,
              getFullTree: getFullTree,
              globalKeyGet: globalKeyGet,
              globalKeySet: globalKeySet,
              additionalData: additionalData,
              makeBlock: makeBlock,
              node: itemWithoutHandlers.current,
              id: id,

              // Ações herdadas de ScriptBlock antes da refatoração
              onDelete: onDelete,
              setDefinition: setDefinition,
              mapAttributesName: mapAttributesName,
              onDuplicate: onDuplicate,
              getSelectorOptions: getSelectorOptions,
            })}

            {!!children.length && (
              <Tree
                id={id}
                path={path}
                placeholder={placeholder}
                dragging={dragging}
                data={data}
                type={type}
                children={children}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
                onDrop={onDrop}
                treeAdd={treeAdd}
                additionalData={additionalData}
                treeRemove={treeRemove}
                treeUpdate={treeUpdate}
                getFullTree={getFullTree}
                globalKeyGet={globalKeyGet}
                globalKeySet={globalKeySet}
                makeBlock={makeBlock}
              />
            )}
          </div>
        </div>
      </div>

      {!isOnly && (
        <AddBlockButton parent={parent} item={itemWithoutHandlers.current} alwaysShow={isLast} />
      )}
    </div>
  );
};

export default Item;
