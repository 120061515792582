import React from "react";
import PropTypes from "prop-types";

import ListGroupAlert from './ListGroupAlert';
import GoogleLogin from '@/components/representatives/GoogleLogin';
import TutorialButton from "@/components/guided_tutorial/TutorialButton";

import useCurrentUserStore from "@/store/currentUserStore";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from "use-context-selector";
import { can } from "@/lib/user-permission"

import { ListGroupContainer } from "./styles";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'calendar_schedule_plugin'
});

const SellersSelector = ({
  selectedRepresentativesIds,
  setDefinition,
  findRepresentatives,
  setSelectedRepresentativesIds,
}) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const allowConnectionToGoogle = useContextSelector(ScriptContext, ({ state }) => state.allowConnectionToGoogle);
  const representatives = useContextSelector(ScriptContext, ({ state }) => state.representatives);
  const { isPurchased, maxRepresentativesAllowed } = useCurrentUserStore((state) => state.currentUser.plugins.calendarSchedule);

  const allowedRepresentatives = representatives.filter((representative) => {
    return !selectedRepresentativesIds.includes(representative.id);
  });

  const selectedRepresentatives = representatives.filter((representative) => {
    return selectedRepresentativesIds.includes(representative.id)
  });

  const handleAddRepresentative = (representativeId) => {
    if (!hasPermission) return;
    if (selectedRepresentativesIds.indexOf(representativeId) !== -1) return;

    const updatedRepresentatives = [representativeId, ...selectedRepresentativesIds];
    setSelectedRepresentativesIds(updatedRepresentatives);
    setDefinition({ representatives: updatedRepresentatives });
  }

  const handleRemoveRepresentative = (representativeId) => {
    if (!hasPermission) return;
    if (selectedRepresentativesIds.indexOf(representativeId) === -1) return;

    const updatedRepresentatives = selectedRepresentativesIds.filter((id) => id !== representativeId);
    setSelectedRepresentativesIds(updatedRepresentatives);
    setDefinition({ representatives: updatedRepresentatives });
  }

  return (
    <div className='form-group'>
      <div className="form-label">
        {I18n.t("manage_flow.scheduler_block.calendar_accounts_label")}
        <TutorialButton code={95495} />
      </div>

      <div className="row g-3 mb-3">
        <div className="col-lg-6">
          <div className="list-group list-group-flush border rounded">
            <div className="list-group-header px-2">
              <div className="row">
                <div className='col'>
                  {I18n.t("views.manage_flows.scheduler_block.synced_accounts_available")}
                  <span className="form-help ms-2 d-none d-md-inline-flex" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover"
                    data-bs-content={I18n.t("views.manage_flows.scheduler_block.team_linked_accounts_message")}
                  >?</span>
                </div>

                {isPurchased && (
                  <div
                    className='col-auto'
                    data-bs-toggle="tooltip"
                    title={I18n.t("views.manage_flows.scheduler_block.synced_accounts_limit_message")}
                  >
                    <a href="/users/edit#tab-google" target="_blank" className="float-end badge fw-bold bg-primary-lt">
                      {representatives.length} / {maxRepresentativesAllowed} {I18n.t(
                        "views.manage_flows.scheduler_block.linked_accounts"
                      )}
                    </a>
                  </div>
                )}
              </div>
            </div>

            <ListGroupContainer>
              {allowedRepresentatives.map((representative) => {
                return (
                  <div className="list-group-item py-2" key={representative.id}>
                    <div className="d-flex align-items-center">
                      <label className="form-switch col-auto" data-bs-toggle="tooltip" title="Ativar Conta">
                        <input
                          className="form-check-input cursor-pointer"
                          type="checkbox"
                          onClick={() => handleAddRepresentative(representative.id)}
                        />
                      </label>

                      <div className="ms-2 text-truncate">
                        <div className="text-body fw-medium">
                          {representative.name}
                        </div>
                        <div className="d-block text-muted text-truncate">
                          {representative.email}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

              {!representatives.length ? (
                <ListGroupAlert
                  title={I18n.t("views.manage_flows.scheduler_block.no_synced_accounts_message")}
                  subtitle={I18n.t("views.manage_flows.scheduler_block.register_google_accounts_message")}
                  status='danger'
                />
              ) : (!allowedRepresentatives.length ? (
                <ListGroupAlert
                  title={I18n.t("views.manage_flows.scheduler_block.all_selected_message")}
                  subtitle={I18n.t("views.manage_flows.scheduler_block.register_new_google_accounts_message")}
                  status='primary'
                />
              ) : '')}
            </ListGroupContainer>

            <div className="p-2 d-flex flex-column h-100 align-items-center justify-content-center py-2">
              {allowConnectionToGoogle && (
                <>
                  <GoogleLogin
                    onSuccessCallback={() => findRepresentatives()}
                    enabled={hasPermission}
                    className="btn-primary w-100"
                  />

                  <div className="hr-text my-3">{I18n.t("views.manage_flows.script_editor.or")}</div>
                </>
              )}

              <a href="/users/edit#tab-google" target='_blank'>
                <i className="ti ti-users me-2"></i>
                { representatives.length ?
                  I18n.t("views.manage_flows.scheduler_block.manage_google_calendar_accounts") :
                  I18n.t("views.manage_flows.scheduler_block.register_your_team")
                }
              </a>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="list-group list-group-flush border rounded h-100">
            <div className="list-group-header px-2">
              {I18n.t("views.manage_flows.scheduler_block.active_accounts")}
              <span className="form-help ms-2 d-none d-md-inline-flex" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover"
                data-bs-content={` ${I18n.t("views.manage_flows.scheduler_block.active_team_accounts_message")}`}
              >?</span>
            </div>

            <ListGroupContainer>
              {selectedRepresentatives.map((representative) => {
                return  (
                  <div className={`list-group-item py-2 ${selectedRepresentatives.length === 1 ? 'border-0' : ''}`} key={representative.id}>
                    <div className="d-flex align-items-center">
                      <label className="form-switch col-auto" data-bs-toggle="tooltip" title="Remover Conta">
                        <input
                          className="form-check-input form-check-green cursor-pointer"
                          type="checkbox"
                          checked
                          onClick={() => handleRemoveRepresentative(representative.id)}
                        />
                      </label>

                      <div className="ms-2 text-truncate">
                        <div className="text-body fw-medium">
                          {representative.name}
                        </div>
                        <div className="text-muted text-truncate">
                          {representative.email}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

              {!selectedRepresentatives.length && (
                <ListGroupAlert
                  title={I18n.t("views.manage_flows.scheduler_block.no_active_accounts_message")}
                  subtitle={I18n.t("views.manage_flows.scheduler_block.activate_account_instructions")}
                  status='danger'
                  strongAlert
                  preventBorder
                />
              )}
            </ListGroupContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

SellersSelector.propTypes = {
  selectedRepresentativesIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setDefinition: PropTypes.func.isRequired,
  findRepresentatives: PropTypes.func.isRequired,
  setSelectedRepresentativesIds: PropTypes.func.isRequired,
};

export default SellersSelector;
