import { useState, useEffect } from "react";

import ListMultipleUrls from "../AddWebsiteLink/components/ListMultipleUrls";

import { useAssistantContext } from "@/contexts/AssistantContext";
import useCreateYourAiAssistant from "@/hooks/useCreateYourAiAssistant";

import parse from "html-react-parser";

import axios from "@/lib/axios";

import * as S from "./styles";

const Alert = ({ onSeePages, onCancel, onClose }) => {
  useCreateYourAiAssistant("/content");

  const { assistant } = useAssistantContext();
  const pendingBase = assistant.pending_knowledge_base;
  const [showModal, setShowModal] = useState(false);
  const [text, setText] = useState("");
  const [btnSeePages, setBtnSeePages] = useState(false);
  const [btnCancel, setBtnCancel] = useState(false);
  const [typeAlert, setTypeAlert] = useState("alert-warning");
  const [btnClose, setBtnClose] = useState(false);

  const handleSeePages = () => {
    setShowModal(true);
    if (typeof onSeePages === "function") onSeePages();
  };

  const handleDeleteMultipleUrl = async () => {
    await axios
      .delete(`/ai/assistants/delete-multiple-url/${assistant.id}`)
      .finally(() => toastr.success(I18n.t("views.leadster_ai.page.add_content.add_links.alerts.success_on_cancel")));
  };

  const handleCancel = () => {
    if (pendingBase?.base_type === "url" && pendingBase?.has_multiple_url_process_data === true) {
      handleDeleteMultipleUrl();
    }
    if (typeof onCancel === "function") onCancel();
  };

  const handleClose = () => {
    if (typeof onClose === "function") onClose();
  };

  const resetSettings = () => {
    setText("");
    setBtnSeePages(false);
    setBtnCancel(false);
    setTypeAlert("alert-warning");
  };

  useEffect(() => {
    resetSettings();

    if (pendingBase?.base_type === "file") {
      setText(I18n.t("views.leadster_ai.page.add_content.loading.file", { filename: pendingBase.filename }));
    }

    if (pendingBase?.base_type === "url" && pendingBase?.has_multiple_url_process_data === false) {
      setText(I18n.t("views.leadster_ai.page.add_content.loading.url", { url: pendingBase.url }));
    }

    if (pendingBase?.base_type === "url" && pendingBase?.has_multiple_url_process_data === true) {
      if (pendingBase?.multiple_url_process_status === "searching_pages") {
        setText(I18n.t("views.leadster_ai.page.add_content.loading.url", { url: pendingBase.url }));
        setBtnCancel(true);
      }
      if (pendingBase?.multiple_url_process_status === "waiting_for_user") {
        setText(I18n.t("views.leadster_ai.page.add_content.loading.select_urls"));
        setBtnSeePages(true);
        setBtnCancel(true);
      }
      if (pendingBase?.multiple_url_process_status === "recording_data") {
        setText(I18n.t("views.leadster_ai.page.add_content.loading.process_urls", { url: pendingBase.url }));
      }
      if (pendingBase?.multiple_url_process_status === "done") {
        setText(I18n.t("views.leadster_ai.page.add_content.loading.sucess_urls", { url: pendingBase.url }));
        setTypeAlert("alert-success");
        setBtnClose(true);
      }
    }
  }, [pendingBase?.multiple_url_process_status, assistant]);

  return (
    !!pendingBase && (
      <S.Content typeAlert={typeAlert}>
        <S.Spinner hidden={pendingBase?.multiple_url_process_status === "waiting_for_user"} />
        <div className="flex-grow-1 text-break">
          {parse(text)}
          <S.SeePages hidden={!btnSeePages} onClick={handleSeePages}>
            {I18n.t("shared.actions.see_pages")}
          </S.SeePages>
          <S.Cancel hidden={!btnCancel} onClick={handleCancel}>
            {I18n.t("shared.actions.cancel")}
          </S.Cancel>
          <div
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="close"
            hidden={!btnClose}
            onClick={handleClose}
          ></div>
          {showModal && <ListMultipleUrls show={showModal} onClose={() => setShowModal(false)} />}
        </div>
      </S.Content>
    )
  );
};

export default Alert;
