import { useState } from "react";

import useUpdateEffect from "@/hooks/useUpdateEffect";

import ReactPaginate from "react-paginate";

import { classBinding } from "@/utils/helpers";

const Pagination = ({
  pageCount = 1,
  initialPerPage = 10,
  isPageRangeEnabled = false,
  perPageRange = [10, 15, 25],
  className = "",
  onCurrentPageChange = (page) => {},
  onPerPageChange = (perPage) => {},
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(initialPerPage);

  const handlePageChange = (event) => {
    setCurrentPage(event.selected);
  };

  const handleChangePerPage = (event) => {
    setPerPage(event.target.value);
  };

  useUpdateEffect(() => {
    onCurrentPageChange(currentPage + 1);
  }, [currentPage]);

  useUpdateEffect(() => {
    setCurrentPage(0);
    onPerPageChange(perPage);
  }, [perPage]);

  if (pageCount <= 1) return null;

  return (
    <div
      className={classBinding(
        {
          "justify-content-between": isPageRangeEnabled,
          "justify-content-end": !isPageRangeEnabled,
        },
        `d-flex align-items-center gap-2 my-3 ${className}`
      )}
    >
      {isPageRangeEnabled && (
        <div className="d-flex align-items-center gap-2">
          <label className="form-label text-nowrap mb-0">
            {I18n.t("shared.pagination.per_page")}
          </label>
          <select
            className="form-control form-control-sm form-select"
            value={perPage}
            onChange={handleChangePerPage}
          >
            {perPageRange.map((countItens) => (
              <option value={countItens} key={countItens}>
                {countItens}
              </option>
            ))}
          </select>
        </div>
      )}

      <ReactPaginate
        forcePage={currentPage}
        pageCount={pageCount}
        onPageChange={handlePageChange}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        renderOnZeroPageCount={null}
        previousLabel={<i className="ti ti-chevron-left icon" />}
        nextLabel={<i className="ti ti-chevron-right icon" />}
        breakLabel={<i className="ti ti-dots icon" />}
        containerClassName="pagination mb-0 gap-1"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item disabled"
        breakLinkClassName="page-link text-muted"
        activeClassName="active"
        disabledClassName="disabled"
      />
    </div>
  );
};

export default Pagination;
