import { Button, Card, OverlayTrigger, Popover } from "react-bootstrap";

import useCurrentUserStore from "@/store/currentUserStore";
import useResponseTracker from "./hooks/useResponseTracker";

import StartNewTrialButton from "@/components/leadster_ia/shared/StartNewTrialButton";

const ResponseLimit = () => {
  const { isNational,
    locale: userLocale,
    plugins: { leadsterAi: { allowedNewTrial } }} = useCurrentUserStore((state) => state.currentUser);

  const { limitResponses, consumedResponses, reachedLimit } = useResponseTracker();

  const handleWhatsAppButton = () => {
    const phoneNumberAi = "5541991625792";

    if (window.innerWidth <= 600) {
      window.location.href = `https://wa.me/${phoneNumberAi}`;
    } else {
      window.location.href = `https://web.whatsapp.com/send?phone=${phoneNumberAi}`;
    }
  };

  return (
    <Card>
      <Card.Body>
        <div className="fs-3 fw-medium mb-2">
          {I18n.t("views.leadster_ai.page.response_limit.title")}

          <OverlayTrigger
            overlay={
              <Popover className="bg-white p-2">
                {I18n.t("views.leadster_ai.page.response_limit.tooltip")}
              </Popover>
            }
          >
            <span className="form-help d-none d-sm-inline px-1 ms-2">?</span>
          </OverlayTrigger>
        </div>

        <div className="mb-2 small">
          {I18n.t("views.leadster_ai.page.response_limit.subtitle")}
        </div>

        <div className="h3 fw-medium">
          {I18n.t("views.leadster_ai.page.response_limit.limit", {
            limit: limitResponses.toLocaleString(userLocale, { minimumFractionDigits: 0 }),
            consumed: consumedResponses.toLocaleString(userLocale, { minimumFractionDigits: 0 }),
           })}
        </div>

        {reachedLimit && !allowedNewTrial && (
          <>
            <hr className="my-3"/>

            <div className="fw-medium p-2 bg-danger-lt small rounded mb-2">
              {I18n.t("views.leadster_ai.page.response_limit.alert_exceded")}
            </div>

            <div className="d-grid gap-2">
              <div className="fw-medium small text-center">
                {I18n.t("views.leadster_ai.page.response_limit.alert_buy")}
              </div>

              {isNational ? (
                <Button variant="outline-primary" className="w-100" onClick={handleWhatsAppButton}>
                  <i className="ti ti-brand-whatsapp me-2 fs-1"/>
                  <span>{I18n.t("views.leadster_ai.page.response_limit.contact_us")}</span>
                </Button>
              ) : (
                <Button variant="outline-primary" className="w-100 contact-tidio">
                  <i className="ti ti-messages me-2 fs-1"/>
                  <span>{I18n.t("views.leadster_ai.page.response_limit.contact_us")}</span>
                </Button>
              )}
            </div>
          </>
        )}

        {allowedNewTrial && (
          <>
            <hr className="my-3"/>

            <div className="rounded-1 bg-primary-lt p-2">
              <p className="text-body fw-medium small mb-0">
                {I18n.t("views.leadster_ai.page.response_limit.allowed_trial.text_1")}{" "}
                {I18n.t("views.leadster_ai.page.response_limit.allowed_trial.text_2")}
              </p>
            </div>

            <div className="border border-primary shadow text-center rounded-1 mt-3 p-3">
              <p className="small"><b>{I18n.t("views.leadster_ai.page.response_limit.allowed_trial.text_3")}</b></p>

              <StartNewTrialButton variant="outline-primary"/>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ResponseLimit;
