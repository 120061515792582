import { createContext, useContext, useReducer } from "react";

import { ACTIONS } from "./state/constants";
import { assistantReducer, initialState } from "./state/reducer";

export const AssistantContext = createContext({});

function AssistantProvider({ children }) {
  const [state, dispatch] = useReducer(assistantReducer, initialState);

  const updateSettings = (settings) => {
    dispatch({ type: ACTIONS.UPDATE_SETTINGS, payload: settings });
  };

  const resetAssistantData = () => {
    dispatch({ type: ACTIONS.RESET_ASSISTANT_DATA });
  };

  const setAssistantsList = (assistants) => {
    dispatch({ type: ACTIONS.SET_ASSISTANTS_LIST, payload: assistants });
  };

  const setAssistant = (assistant) => {
    dispatch({ type: ACTIONS.SET_ASSISTANT, payload: assistant });
  };

  const debitCharactersUsed = (countCharacters) => {
    dispatch({ type: ACTIONS.DEBIT_CHARACTERS_USED, payload: countCharacters });
  };

  const removeAssistant = (assistantId) => {
    dispatch({ type: ACTIONS.REMOVE_ASSISTANT, payload: assistantId });
  };

  const addPendingBase = (knowledgeBase) => {
    dispatch({ type: ACTIONS.ADD_PENDING_BASE, payload: knowledgeBase });
  };

  const removePendingBase = () => {
    dispatch({ type: ACTIONS.REMOVE_PENDING_BASE });
  };

  return (
    <AssistantContext.Provider
      value={{
        ...state,
        updateSettings,
        resetAssistantData,
        setAssistantsList,
        setAssistant,
        debitCharactersUsed,
        removeAssistant,
        addPendingBase,
        removePendingBase,
      }}
    >
      {children}
    </AssistantContext.Provider>
  );
}

export function useAssistantContext() {
  return useContext(AssistantContext);
}

export default AssistantProvider;
