import React, { cloneElement, useEffect } from "react";
import PropTypes from "prop-types";

import AlertPlaceholder from "./AlertPlaceholder";

import { can } from "@/lib/user-permission";
import { classBinding } from "@/utils/helpers";

const PermissionsGate = ({
  children,
  scope = { action: '', subject: '' },
  forceHasPermission,

  hideOnError = false,

  useLockIcon = false,
  iconCentered = false,
  iconSmall = false,
  iconExtraSmall = false,

  usePopover = false,
  usePlaceholderError = false,

  customComponentProps = {},

  useRenderError = false,
  RenderError = () => <></>,
}) => {
  let hasPermission = can(scope);

  if (typeof forceHasPermission === "boolean") {
    hasPermission = forceHasPermission;
  }

  useEffect(() => {
    if (usePopover) {
      window.initJqueryPopover();
    }
  });

  const dynamicAttributes = () => {
    const childrenClassName = children.props.className;

    const classes = classBinding({
      "disabled": true,
      "feature-disabled": true,
      "cursor-not-allowed": true,
      "lock-icon": useLockIcon,
      "lock-icon-center": iconCentered,
      "lock-icon-sm": iconSmall,
      "lock-icon-xs": iconExtraSmall,
      "cannot-popover": usePopover,
    }, childrenClassName);

    return {
      className: classes,
      ...customComponentProps,
    };
  };

  if (hasPermission) {
    return <>{children}</>;
  }

  if (hideOnError) {
    return <></>
  }

  if (useRenderError) {
    return <RenderError />
  }

  if (!children.props.children)  {
    return cloneElement(children, { ...dynamicAttributes() });
  }

  const childrenContent = [
    usePlaceholderError ? [<AlertPlaceholder key=".alert-placeholder" />] : [],
    ...React.Children.map(children.props.children, (child) => child),
  ];

  return cloneElement(
    children,
    { ...dynamicAttributes() },
    childrenContent
  );
};

PermissionsGate.propTypes = {
  scopes: PropTypes.arrayOf(PropTypes.string),
  forceHasPermission: PropTypes.bool,
  showWhenError: PropTypes.bool,
  useLockIcon: PropTypes.bool,
  iconCentered: PropTypes.bool,
  usePopover: PropTypes.bool,
  iconSmall: PropTypes.bool,
  customComponentProps: PropTypes.object,
  useRenderError: PropTypes.bool,
  RenderError: PropTypes.elementType,
};

export default PermissionsGate;
