export const ACTIONS = {
  UPDATE_SETTINGS: "UPDATE_SETTINGS",
  RESET_ASSISTANT_DATA: "RESET_ASSISTANT_DATA",

  SET_ASSISTANTS_LIST: "SET_ASSISTANTS_LIST",
  SET_ASSISTANT: "SET_ASSISTANT",
  REMOVE_ASSISTANT: "REMOVE_ASSISTANT",
  DEBIT_CHARACTERS_USED: "DEBIT_CHARACTERS_USED",

  ADD_PENDING_BASE: "ADD_PENDING_BASE",
  REMOVE_PENDING_BASE: "REMOVE_PENDING_BASE",
};
