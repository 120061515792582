import React, { useState, useMemo, useEffect, useRef } from "react";

import AddFirstBlockMessage from "./AddFirstBlockMessage";
import ScriptCardFooter from "../../ScriptCardFooter";
import TreeEditor from "./TreeEditor";
import ConversionCallsModal from "../ConversionCallsExample/Modal";
import PermissionsGate from '@/components/permissions/PermissionsGate';
import TutorialButton from "@/components/guided_tutorial/TutorialButton";
import RichTextEditor from "@/components/shared/RichTextEditor";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';
import { can } from "@/lib/user-permission";

import parse from "html-react-parser";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const SIZE_LIMIT_OF_TOOLTIP_MESSAGE = 200;

const ConversationFlowContent = ({ isMobile }) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);
  const desktopContent = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.desktopContent);
  const mobileContent = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.mobileContent);
  const modalRef = useRef();

  let convoScript = desktopContent;
  let dispatchActionType = "SET_DESKTOP_CONVOSCRIPT_VALUE";

  if (isMobile) {
    convoScript = mobileContent;
    dispatchActionType = "SET_MOBILE_CONVOSCRIPT_VALUE";
  }

  const [tooltipMessage, setTooltipMessage] = useState(convoScript.tooltipMessage || "");

  useEffect(() => {
    if (convoScript.tooltipMessage !== tooltipMessage) {
      setTooltipMessage(convoScript.tooltipMessage);
    };

  }, [convoScript.tooltipMessage]);

  const handleEditorChange = (msg, editor) => {
    const cleanedMsg = editor.getContent({ format: 'text' })

    if(cleanedMsg.length <= SIZE_LIMIT_OF_TOOLTIP_MESSAGE || msg.length < tooltipMessage.length){
      setTooltipMessage(msg);
      setScriptValue("tooltipMessage", msg);
    }
  }

  const handleEditorPaste = (event, editor) => {
    const pastedContent = event.clipboardData.getData('text');

    if (pastedContent.length > SIZE_LIMIT_OF_TOOLTIP_MESSAGE) {
      const truncatedContent = pastedContent.slice(0, SIZE_LIMIT_OF_TOOLTIP_MESSAGE);

      editor.setContent(truncatedContent);
      event.preventDefault();
    }
  }

  const handleKeyDown = (event, editor) => {
    const contentLength = editor.getContent({ format: 'text' }).length;
    const eventKey = event.key;

    if (contentLength >= SIZE_LIMIT_OF_TOOLTIP_MESSAGE && !event.ctrlKey && !event.metaKey && !(eventKey === "Backspace" || eventKey === "Delete")) {
      event.preventDefault();
    }
  };

  const handleEditorBeforeAddUndo = (event, editor) => {
    const length = editor.getContent({ format: 'text' }).length;

    if (length > SIZE_LIMIT_OF_TOOLTIP_MESSAGE) {
      event.preventDefault();
    }
  };

  const setScriptValue = useMemo(() =>
    _.debounce((key, value) => {
      dispatch({ type: dispatchActionType, payload: { key, value } });
      dispatch({ type: "SET_PREVIEW_CONVOSCRIPT_VALUE", payload: { key, value } });
    }, 200)
  , []);

  const cardFooter = {
    title: I18n.t("views.manage_flows.script_editor.conversion_call_tips"),
    description: I18n.t("views.manage_flows.script_editor.conversion_call_description"),
  };

  const openConversionModal = () => {
    if (!hasPermission) return;

    $(modalRef.current).modal("show");
    MixpanelService.track('script_open_conversion_call_modal')
  }

  return (
    <>
      <div className="card-body">
        <div className="h4 inline-block">
          {I18n.t("views.manage_flows.script_editor.conversion_call")}
          <TutorialButton code={95408} />
        </div>

        <RichTextEditor
          value={tooltipMessage}
          init={{
            height: 120,
            menubar: false,
            plugins: "emoticons",
            toolbar: "bold italic underline | emoticons",
            smart_paste: false,
          }}
          onEditorChange={handleEditorChange}
          onPaste={handleEditorPaste}
          onKeyDown={handleKeyDown}
          onBeforeAddUndo={handleEditorBeforeAddUndo}
        />

        <div className="mt-3">
          <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon iconCentered iconSmall usePopover>
            <button
              type="button"
              className="btn btn-primary"
              onClick={openConversionModal}
            >
              <i className="ti ti-search icon"/>
              {I18n.t("views.manage_flows.script_editor.see_best_calls")}
            </button>
          </PermissionsGate>

          <ConversionCallsModal isMobile={isMobile} ref={modalRef} />
        </div>
      </div>

      <ScriptCardFooter
        title={cardFooter.title}
        style={{
          border: 0,
          borderTop: "1px solid #0028641f",
          borderBottom: "1px solid #0028641f",
          borderRadius: 0,
          boxShadow: "none",
        }}
      >
        <p className="mb-0 tracking-wide">{parse(cardFooter.description)}</p>
      </ScriptCardFooter>

      <div className="card-body">
        <div className="h2 mb-3 inline-block">
          {I18n.t("views.manage_flows.script_editor.question_flow")}
        </div>

        <AddFirstBlockMessage isMobile={isMobile} />
        <TreeEditor isMobile={isMobile} name="ConversationFlow" />
      </div>
    </>
  );
};

export default ConversationFlowContent;
