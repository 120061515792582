import React, { useState, useEffect, useRef } from "react";

import Form from "react-jsonschema-form";
import ax from "@/lib/axios";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import ScriptFieldsContext from "./ScriptFieldsContext";
import { CustomTextWidget, CustomSelectWidget } from "./CustomWidgets";
import CopyScriptCode from "../../components/onboard/CopyScriptCode"

import { ContainerStep4 } from './style'

const customWidgets = {
  TextWidget: CustomTextWidget,
  URLWidget: CustomTextWidget,
  SelectWidget: CustomSelectWidget
};

const ErrorListTemplate = ({ errors }) => (
  <div className="alert alert-danger" role="alert">
    <h4 className="alert-title">{I18n.t("views.integrations.step04.success.error_creating_integration")}&hellip;</h4>
    <div className="text-muted">{I18n.t("views.integrations.step04.success.check_the_fields_below")}</div>
  </div>
);

const transformErrors = errors => {
  return errors
    .map(error => {
      if (error.name === "required") error.message = I18n.t("views.integrations.step04.required_field");

      if (error.name === "format" && error.params.format === "uri")
        error.message = I18n.t("views.integrations.step04.success.use_a_valid_url");

      if (error.message === "should be equal to one of the allowed values")
        return null;

      return error;
    })
    .filter(Boolean);
};

const Step4 = props => {
  const { state } = useStateMachine(updateAction);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [schema, setSchema] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const [scriptFields, setScriptFields] = useState({});
  const [bluePrintId, setBluePrintId] = useState(false);
  const [webhookScript, setWebhookScript] = useState(false);
  const [errorIntegration, setErrorIntegration] = useState(false);
  const [errorIntegrationDescription, setErrorIntegrationDescription] = useState('');

  const jsonSchemaForm = useRef();

  const { register, setValue, getValues, handleSubmit } = useForm({
    defaultValues: {
      fields: typeof state.data.config === "undefined" ? {} : state.data.config.fields,
      metadata: typeof state.data.config === "undefined" ? {} : (({ fields, ...o }) => o)(state.data.config)
    }
  });

  useEffect(() => {
    register("name.fields");
    register("name.metadata");
  }, [register]);

  const onSubmit = async data => {
    setIsSubmitting(true);

    try {
      let req;
      if (
        typeof props.integration === "undefined" ||
        props.integration === null
      ) {
        req = ax.post(`/integrations`, {
          integration: {
            config: { fields: data.fields, ...data.metadata },
            integration_blueprint_id: state.data.integration_blueprint_id,
            allowed_scripts: state.data.allowed_scripts.map(k => k.value),
            allowed_events: [state.data.allowed_events]
          }
        });
      } else {
        req = ax.put(`/integrations/${props.integration.id}`, {
          integration: {
            config: { fields: data.fields, ...data.metadata },
            integration_blueprint_id: state.data.integration_blueprint_id,
            allowed_scripts: state.data.allowed_scripts.map(k => k.value),
            allowed_events: [state.data.allowed_events]
          }
        });
      }
      const res = await req;
      setSubmitSuccess(true);
      // TODO: Alterar blueprint_id para blueprint_name
      if(res.data.integration_blueprint_id == 2 || res.data.integration_blueprint_id == 24){
        setBluePrintId(2)
        const res = await ax.post(`/integrations/schema`, {
          data: state.data,
          timeout: 100000
        });
        setScriptFields(res.data.script_fields)
        if( data.fields.fields == undefined || ( data.fields &&  data.fields.fields.length == 0)) {
          setWebhookScript(JSON.stringify({"lead": {
              "id": "00000000-00000000-00000000-00000000-00000000",
              "data": scriptFields,
              "script": {
                "id": 12345,
                "name": I18n.t("views.integrations.step04.flow_name"),
                "...": "..."
              },
              "synced": false,
              "email_to": "...",
              "lead_data": [
                "..."
              ],
              "script_id": 99999,
              "created_at": "2021-01-01T00:00:00.000-03:00",
              "email_from": "leads@neurologic.com.br",
              "updated_at": "2021-01-01T00:00:00.000-03:00",
              "integrations": {},
              "email_subject": "...",
              "script_version_id": 99999
            }}, null, 2))
        }else{
          let json_data = {}
          data.fields.fields.forEach(field => {
            json_data[field["name"]] = "..."
          })
          setWebhookScript(JSON.stringify(json_data,null,2));
        }
      }

      MixpanelService.track('integrations_fourth_step');
    } catch (error) {
      console.error(error);
      setSubmitSuccess(false);
    } finally {
      setIsSubmitting(false);
      window.STATE_MACHINE_RESET && window.STATE_MACHINE_RESET();
    }
  };

  const fieldsOnSubmit = ({ formData }, e) => {
    setValue("fields", formData);
    handleSubmit(onSubmit)();
  };

  const cleanObject = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

  const actionableSendForm = () => {
    cleanObject(jsonSchemaForm.current.state.formData);

    jsonSchemaForm.current.formElement.dispatchEvent(
      new CustomEvent('submit', { cancelable: true, bubbles: true })
    );
  };

  const updateFields = () => {
    const emailIntegration = state.data.integration_blueprint_id === 5

    if (!emailIntegration) return;

    const fields = getValues().fields;
    if(!fields) return;
    const oldSubject = fields.subject

    if (!oldSubject) return;

    const newSubject = oldSubject.replace(/Neurologic/, 'Leadster');
    let newFields = Object.assign({}, fields);
    newFields.subject = newSubject;
    setValue('fields', newFields);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await ax.post(`/integrations/schema`, {
          data: state.data,
          timeout: 100000
        });

        const { schema, ui_schema, script_fields, metadata, error_integration } = res.data;

        if(schema.title === "E-mail"){
          script_fields["Nome do fluxo"] = "fluxo"
        }

        const scopeI18n = "views.integrations.step04.default_variables"

        script_fields[I18n.t(`${scopeI18n}.url_referrer`)] = "url_referrer"
        script_fields[I18n.t(`${scopeI18n}.script_name`)] = "script_name"
        script_fields[I18n.t(`${scopeI18n}.score`)] = "score"
        script_fields[I18n.t(`${scopeI18n}.utm_content`)] = "utm_content"
        script_fields[I18n.t(`${scopeI18n}.utm_medium`)] = "utm_medium"
        script_fields[I18n.t(`${scopeI18n}.utm_source`)] = "utm_source"
        script_fields[I18n.t(`${scopeI18n}.utm_campaign`)] = "utm_campaign"
        script_fields[I18n.t(`${scopeI18n}.utm_term`)] = "utm_term"
        script_fields[I18n.t(`${scopeI18n}.geolocation_city`)] = "geolocation_city"
        script_fields[I18n.t(`${scopeI18n}.geolocation_region`)] = "geolocation_region"
        script_fields[I18n.t(`${scopeI18n}.lead_created_at`)] = "lead_created_at"
        script_fields[I18n.t(`${scopeI18n}.number_of_whatsapp`)] = "number_of_whatsapp"
        script_fields[I18n.t(`${scopeI18n}.whatsapp_sended`)] = "whatsapp_sended?"
        script_fields[I18n.t(`${scopeI18n}.organization_of_scheduling`)] = "organization_of_scheduling"
        script_fields[I18n.t(`${scopeI18n}.datetime_of_scheduling`)] = "datetime_of_scheduling"
        script_fields[I18n.t(`${scopeI18n}.meet_of_scheduling`)] = "meet_of_scheduling"
        script_fields[I18n.t(`${scopeI18n}.schedule_sended`)] = "schedule_sended?"
        script_fields[I18n.t(`${scopeI18n}.assistant_history`)] = "assistant_history"


        setSchema(schema);
        setUiSchema(ui_schema);
        setScriptFields(script_fields);
        setValue("metadata", metadata);
        setErrorIntegration(error_integration['status']);
        setErrorIntegrationDescription(error_integration['message']);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    updateFields();
    fetchData();
  }, []);

  const FormView = () => (
    <ContainerStep4>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            {I18n.t("views.integrations.step04.title")}
          </h3>
        </div>

        <div className="card-body" style={{ minHeight: "10em" }}>
          <div className={`dimmer ${isLoading ? "active" : ""}`}>
            <div className="loader" />
            <div className="dimmer-content">
              {schema.help_link &&
                <a href={schema.help_link} target="_blank">{I18n.t("views.integrations.step04.doubts_watch_the_video")}</a>
              }
              <ScriptFieldsContext.Provider value={scriptFields}>
                <Form
                  schema={schema}
                  uiSchema={uiSchema}
                  widgets={customWidgets}
                  onSubmit={fieldsOnSubmit}
                  formData={getValues().fields}
                  ref={jsonSchemaForm}
                  noHtml5Validate={true}
                  showErrorList={true}
                  ErrorList={ErrorListTemplate}
                  transformErrors={transformErrors}
                >
                  <></>
                </Form>
              </ScriptFieldsContext.Provider>
            </div>
          </div>
        </div>

        <div className="card-footer bg-white">
          <div className="d-flex">
            <button className="btn" type="button" onClick={() => props.history.goBack()}>
              {I18n.t("shared.actions.return")}
            </button>

            <button
              className="btn btn-primary ms-auto"
              type="submit"
              onClick={actionableSendForm}
            >
              {typeof props.integration === "undefined" ||
              props.integration === null
                ? I18n.t("views.integrations.step04.success.button_create_integration")
                : I18n.t("shared.actions.save")}
            </button>
          </div>
        </div>
      </div>
    </ContainerStep4>
  );

  if (isSubmitting || submitSuccess !== null) {
    return (
      <div className="card">
        <div className="card-body">
          <div className={`dimmer ${isSubmitting ? "active" : ""}`}>
            <div className="loader" />
            <div className="dimmer-content">
              {!isSubmitting && submitSuccess && !errorIntegration && (
                <div className="alert alert-success mb-0" role="alert">
                  <h3 className="text-success">
                    {I18n.t("views.integrations.step04.success.title")}
                  </h3>

                  <p className="text-muted mb-2">
                    {I18n.t("views.integrations.step04.success.description")}
                  </p>

                  {bluePrintId == 2 && (
                    <>
                      <div className="mt-2">
                        <div className="h4">
                          {I18n.t("views.integrations.step04.success.text")}
                        </div>

                        <pre>
                          <code>
                            {webhookScript}
                          </code>
                        </pre>
                      </div>

                      <div className="btn-copy" style={{'float': 'right', 'marginTop': '-18px'}}>
                        <CopyScriptCode code={webhookScript}></CopyScriptCode>
                      </div>
                    </>
                  )}
                  <div className="btn-list">
                    <a href="/integrations" className="btn btn-success">
                      {I18n.t("views.integrations.step04.success.button_view_integrations")}
                    </a>
                  </div>
                </div>
              )}

              {(!isSubmitting && !submitSuccess) || errorIntegration && (
                <div className="alert alert-danger mb-0" role="alert">
                  <h4 className="alert-title">{I18n.t("views.integrations.step04.success.error_creating_integration")}&hellip;</h4>
                  <div className="text-muted">
                    {errorIntegrationDescription}
                  </div>
                  <div className="text-muted">
                    {I18n.t("views.integrations.step04.success.contact_support")}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <FormView />;
  }
};

export default withRouter(Step4);
